import React from "react"
import { Link } from "gatsby"
import { StructuredText } from 'react-datocms'
import InlineVideoEmbed from '../InlineVideoEmbed'
import InlineImage from '../InlineImage'
import InlineLinkCard from '../InlineLinkCard'
import InternalLink from '../InternalLink'
import InlineStatistic from '../InlineStatistic'
import "./index.scss"

function columnStyleClasses(backgroundColor) {
  if (backgroundColor) {
    return 'section-layout__column--background-'.concat(backgroundColor)
  }
}

export default function InlineColumn({columnData, columnClasses=''}) {

  return (
    <div key={columnData.id} className={['section-layout__column standard-content', columnClasses, columnStyleClasses(columnData.backgroundColor)].join(' ')}>
      <StructuredText 
        data={columnData.content}
        renderLinkToRecord={({ record, children }) => {
          return (<InternalLink
            record={record}
            classes=""
            children={children} 
          />)
        }}
        renderInlineRecord={({ record }) => {
          return null;
        }}
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case "DatoCmsInlineVideoEmbed":
              return <InlineVideoEmbed videoData={record} />
            case  "DatoCmsInlineImage":
              return <InlineImage imageData={record.image} />
            case  "DatoCmsInlineLinkCard":
              return <InlineLinkCard cardData={record} />
            case  "DatoCmsInlineStatistic":
              return <InlineStatistic statData={record} />
            default:
              return null;
          }
        }}
      />
    </div>
  )
}
